import React, { useState} from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ModalComponent from '../../Modal/ModalCom.js';
import { Coastalplacesandthings } from "../../Data.js";
const CoastalPlacesAndThings = () => {
    const data = {
        pageName: "COASTAL PLACES AND THINGS",
        comment: ["After some of the more exacting paintings I completed for", <em key="italic"> Entropy, Limitation, and Death </em>, "series I began to make paintings of coastal sites because they facilitated my desire to “loosen up.”", <br></br>, , <br></br>, "Even more significantly, the change of subject matter represents a shift in my paradigm.  The immersion in subject matter that was born of my own physical limitation was intense.  I wanted to focus on hopeful themes.  From the earliest years I have made pilgrimages to beaches every summer. The curative ocean it is a place where I shed my worries and dream again. Now in a gradual process of bodily recouperation after spinal surgery in 2023, I am less limited than I had been.  An increased physical freedom and promise of restoration coincides with the spirit of this body of work.  My intent is to distill in these paintings the regenerative state-of-being I feel in the presence of coastal spaces."],
        // comment1: ["After some of the more exacting paintings I completed for", <em key="italic"> Entropy, Limitation, and Death </em>, "series I began to make paintings of coastal sites because they facilitated my desire to “loosen up.”", <br></br>, , <br></br>, "Even more significantly, the change of subject matter represents a shift in my paradigm.  The immersion in subject matter that was born of my own physical limitation was intense.  I wanted to focus on hopeful themes.  From the earliest years I have made pilgrimages to beaches every summer. The curative ocean it is a place where I shed my worries and dream again. Now in a gradual process of bodily recouperation after spinal surgery in 2023, I am less limited than I had been.  An increased physical freedom and promise of restoration coincides with the spirit of this body of work.  My intent is to distill in these paintings the regenerative state-of-being I feel in the presence of coastal spaces."]
    }
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };

    return (
        <>
            <div className='stillLifes structureLandscape' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>COASTAL PLACES AND THINGS</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        Coastalplacesandthings.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={Coastalplacesandthings.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);
export default CoastalPlacesAndThings
