import './App.css';
import "./Components/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
// import 'jquery/dist/jquery.min.js'
import BrowserRouterApp from './Components/BrowserRouterApp.js';
import Main from './Prectice.js';
function App() {
  return (
    <>
    {/* <Main/> */}
      <BrowserRouterApp />
    </>
  );
}

export default App;
