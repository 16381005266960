import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
// import { Coastalplacesandthings } from "./Components/Data";
import CoastalPlacesandThings1 from "./assests/img/Batch2/Folly Beach, 2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings2 from "./assests/img/Batch2/The Decampment, 2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings3 from "./assests/img/Batch2/Kayaks at Rest,  2024, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings4 from "./assests/img/Batch2/Beach Paraphernalia 3,  2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings5 from "./assests/img/Batch2/Beach Shapes 3, 2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings6 from "./assests/img/Batch2/Beach Shapes 2, 2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings7 from "./assests/img/Batch2/Beach Shapes 1, 2022, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings8 from "./assests/img/Batch2/Beach Forms 2,  2023, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings9 from "./assests/img/Batch2/Beach Paraphernalia 2, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings10 from "./assests/img/Batch2/Beach Paraphernalia 1, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings11 from "./assests/img/Batch2/Pole, Guy lines, and Sandbag Stake, 2021, acrylic & oil on wood panel.webp";
import CoastalPlacesandThings12 from "./assests/img/Batch2/Shells in Marsh, 2011, acrylic & oil on wood panel.webp";
import "./App.css";

const Coastalplacesandthings = [
    {
        src: CoastalPlacesandThings1,
        srcset: `${CoastalPlacesandThings1}`,
        title: "Folly Beach, 2024, acrylic & oil on wood panel, 45 ½” x 47 7/8”",
        alt: "Folly Beach, 2024, acrylic & oil on wood panel, 45 ½” x 47 7/8”",
        className: "first-img bigimg",
    },
    {
        src: CoastalPlacesandThings2,
        srcset: `${CoastalPlacesandThings2}`,
        title: "The Decampment, 2024, acrylic & oil on wood panel, 47” x 40 ½”",
        alt: "The Decampment, 2024, acrylic & oil on wood panel, 47” x 40 ½”",
        className: "img2 smallimg",
    },
    {
        src: CoastalPlacesandThings3,
        srcset: `${CoastalPlacesandThings3}`,
        title: "Kayaks at Rest,  2024, acrylic & oil on wood panel, 41 ½” x 45 ½”",
        alt: "Kayaks at Rest,  2024, acrylic & oil on wood panel, 41 ½” x 45 ½”",
        className: "first-img bigimg",
    },
    {
        src: CoastalPlacesandThings4,
        srcset: `${CoastalPlacesandThings4}`,
        title: "Beach Paraphernalia 3,  2023, acrylic & oil on wood panel,  42 ½” x 40”",
        alt: "Beach Paraphernalia 3,  2023, acrylic & oil on wood panel,  42 ½” x 40”",
        className: "first-img bigimg",
    },
    {
        src: CoastalPlacesandThings5,
        srcset: `${CoastalPlacesandThings5}`,
        title: "Beach Shapes 3, 2023, acrylic & oil on wood panel, 10” x 8”",
        alt: "Beach Shapes 3, 2023, acrylic & oil on wood panel, 10” x 8”",
        className: "2nd-img bigimgsecond",
    },
    {
        src: CoastalPlacesandThings6,
        srcset: `${CoastalPlacesandThings6}`,
        title: "Beach Shapes 2, 2023, acrylic & oil on wood panel, 8” x 10”",
        alt: "Beach Shapes 2, 2023, acrylic & oil on wood panel, 8” x 10”",
        className: "first-img bigimg",
    },
    {
        src: CoastalPlacesandThings7,
        srcset: `${CoastalPlacesandThings7}`,
        title: "Beach Shapes 1, 2022, acrylic & oil on wood panel, 24” x 32”",
        alt: "Beach Shapes 1, 2022, acrylic & oil on wood panel, 24” x 32”",
        className: "first-img bigimg",
    },
    {
        src: CoastalPlacesandThings8,
        srcset: `${CoastalPlacesandThings8}`,
        title: "Beach Forms 2,  2023, acrylic & oil on wood panel, 8” x 10”",
        alt: "Beach Forms 2,  2023, acrylic & oil on wood panel, 8” x 10”",
        className: "2nd-img smallimg",
    },
    {
        src: CoastalPlacesandThings9,
        srcset: `${CoastalPlacesandThings9}`,
        title: "Beach Paraphernalia 2, 2021, acrylic & oil on wood panel, 22 ½” x 40”",
        alt: "Beach Paraphernalia 2, 2021, acrylic & oil on wood panel, 22 ½” x 40”",
        className: "first-img bigimg",
    },
    {
        src: CoastalPlacesandThings10,
        srcset: `${CoastalPlacesandThings10}`,
        title: "Beach Paraphernalia 1, 2021, acrylic & oil on wood panel, 22” x 34”",
        alt: "Beach Paraphernalia 1, 2021, acrylic & oil on wood panel, 22” x 34”",
        className: "first-img bigimg",
    },
    {
        src: CoastalPlacesandThings11,
        srcset: `${CoastalPlacesandThings11}`,
        title: "Pole, Guy lines, and Sandbag Stake, 2021, acrylic & oil on wood panel, 22” x 21”",
        alt: "Pole, Guy lines, and Sandbag Stake, 2021, acrylic & oil on wood panel, 22” x 21”",
        className: "2nd-img bigimgsecond",
    },
    {
        src: CoastalPlacesandThings12,
        srcset: `${CoastalPlacesandThings12}`,
        title: "Shells in Marsh, 2011, acrylic & oil on wood panel, 24” x 36”",
        alt: "Shells in Marsh, 2011, acrylic & oil on wood panel, 24” x 36”",
        className: "first-img bigimg",
    },

];

const Main = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };

    return (
        <>
            <div className='modal-gallery'>
                <div className='container imagegallery'>
                    <div className='row'>
                        {
                            Coastalplacesandthings.map((photo, index) => {

                                return (
                                    <div className='col-md-4 imagegallerycol' key={index}>
                                        <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className='modal-box'>
                    < ModalGateway >
                        {lightboxOpen && (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={Coastalplacesandthings.map(photo => ({
                                        ...photo,
                                        srcset: `${photo.src} ${photo.width}w`,
                                        caption: photo.title
                                    }))}
                                />
                            </Modal>
                        )}
                    </ModalGateway >
                </div>
            </div>
        </>
    );

};

const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} />
    </div>
);

export default Main;



