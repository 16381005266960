import React from 'react';
import { Helmet } from "react-helmet";
import HomePage from "../assests/img/HarryBoone.webp";
const Home = () => {
    return (
        <div>
            <Helmet>
                <title> Harry Boone Painter - Capturing Beauty in Every Stroke</title>
                <meta name="description" content="Derek Opperman is a Health and Wellness coach and lifestyle expert who supports employees in experiencing the fulfillment of their potential" />
            </Helmet>
            <div id='Home' className='top-spacing'>
                <div className='container'>
                    <div className='row'>
                        <div className='home'>
                            <img src={HomePage} alt='HomePage'  rel="noreferrer noopener" title='HomePage'></img>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home
