import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { StillLifesData } from "../../Data.js";
import ModalComponent from '../../Modal/ModalCom.js';
const StillLifes = () => {
    const data = {
        pageName: "Still Lifes",
        comment: "Although my graduate school training focused largely on direct observation of the human figure, beginning with my MFA thesis work and in the early years of my painting career I increasingly turned to still life (though not your typical sort of still life) as my subject matter, choosing objects and putting them into a variety of positions relative to the lighting in order to explore differing visual phenomena. While some might consider these chosen objects mundane (discarded shoes, medicine bottles, etc.), I really liked finding light and color in that mundanity. At this time, I considered myself a “formalist” and was not concerned with subject matter as a vehicle for meaning or narrative. Rather, meaning was to be derived via the process of visual perception revealed in the paintings."
    }
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };
    return (
        <>
            <div className='stillLifes' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>Still Lifes</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        StillLifesData.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={StillLifesData.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default StillLifes
