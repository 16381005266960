import React from 'react';
import SelectedRecentWorks from './SelectedRecentWorks(2000-PRESENT)';
import SelectedEarlyWorks from "./SelectedEarlyWorks(1982-1999)";
import { Helmet } from "react-helmet";
const Gallery = () => {
    return (
        <>
            <Helmet>
                <title>Gallery - Harry Boone Artist: Explore Captivating Artwork</title>
                <meta name="description" content="Explore the gallery of Harry Boone artists and immerse yourself in captivating artwork. Discover vibrant landscapes, captivating portraits, and more." />
            </Helmet>
            <div className='SelectedEarlyWorks main-gallery top-spacing ' id='SelectedEarlyWorks'>
                <div className='container'>
                    <div className='row'>
                        <SelectedRecentWorks />
                    </div>
                    <div className='row row-top-gap'>
                        <SelectedEarlyWorks />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery

