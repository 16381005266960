import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { StructureOnLandscape } from "../../Data.js";
import ModalComponent from '../../Modal/ModalCom.js';
const StructureOnTheLandscape = () => {
    const data = {
        pageName: "Structure On The Landscape",
        comment: ["In the late 1990s I began a sort of painting that would continue into the twenty-first century. The subjects of these paintings were often witnessed when taking my son to playgrounds and other recreational areas. One time I began meditating on a swing I saw – I was entranced by it for a very long time. I recalled in Martin Buber’s", <em key="italic"> I Thou </em>, " the concept of a contemplative relationship between the self and tree, with the inner cojoining with the outer. That swing extracted from deep inside me an almost spiritual state of being. I thought of Mark Rothko paintings that elicit the very same thing. Not questioning it, I launched a painting that did not look necessarily like what I had literally seen, but rather, using memory, attempted to create for the viewer an experience akin to what I had just felt standing before the swing. Painting in a very abstracted manner with knives and highly keyed color helped me achieve that effect."]
    };
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };
    return (
        <>
            <div className='stillLifes structureLandscape' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>Structure On The Landscape</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        StructureOnLandscape.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={StructureOnLandscape.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default StructureOnTheLandscape
