import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ModalComponent from '../../Modal/ModalCom.js';
import { InteriorsData } from "../../Data.js";
const Interiors = () => {
    const data = {
        pageName: "interiors",
        comment: "Over time, I started to outgrow my purely formalist leanings. One night at about 3 AM after a long painting session made all the more exhausting by the inhalation of too many solvent fumes, I took a break by sitting down in a green chair in my upstairs studio. Sometimes that chair was positioned to face the corner of the room so that I could not see what I had been working on and so that I could rest my mind, along with the help of a restorative beer or two! After some rest, on a whim I started a new painting: namely, this very chair in its environment. I began to understand that this subject matter was deeply personal to me, and I believed that a painting of it might say something more interesting than the things I had been doing. In hindsight this was the beginning of my path to a more expressive brand of painting. By choosing subject matter more thoughtfully and color as the primary vehicle, I was determined to do explore this expressivity more deeply."
    }
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };
    return (
        <>
            <div className='stillLifes' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>interiors</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        InteriorsData.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={InteriorsData.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default Interiors
