import React from 'react'
import { Routes, Route } from "react-router-dom";
import Header from '../Header/Header';
import Home from './Home';
import Gallery from "./Gallery/Gallery"
import Artist from './Artist';
import ArtistStatement01 from './Statement01';
import Press from "./Press";
import Contact from "./Contact";
import Footer from './Footer';
import SelectedEarlyWorks from './Gallery/SelectedEarlyWorks(1982-1999)';
import SelectedRecentWorks from "./Gallery/SelectedRecentWorks(2000-PRESENT)";
import StillLifes from './Gallery/InnerPage/StillLifes';
import Interiors from './Gallery/InnerPage/interiors';
import InteriorsCopy from "./Gallery/InnerPage/HumanAndCopy";
import StructureOnTheLandscape from "./Gallery/InnerPage/StructureOnTheLandscape";
import CoastalPlacesAndThings from "./Gallery/InnerPage/CoastalPlacesAndThings";
import EntropyLimitationAndDeath from "./Gallery/InnerPage/EntropyLimitationAndDeath";
import Playground from "./Gallery/InnerPage/Playgrounds";
import HumanAnimalData from './Gallery/InnerPage/HumanAnimalData';
import Miscellaneous from './Gallery/InnerPage/Miscellaneous';
import TermsConditions from './TermsConditions';
import NoPage from './NoPage';
const BrowserRouterApp = () => {
    return (
        <>
            <Header />
            <Routes>
                <Route index element={<Home />} />
                <Route path="gallery" element={<Gallery />} />
                <Route path="about-the-artist" element={<Artist />} />
                <Route path="artist-statement" element={<ArtistStatement01 />} />
                <Route path="press" element={<Press />} />
                <Route path="Selected-Early-Works" element={<SelectedEarlyWorks />} />
                <Route path="Selected-Recent-Works" element={<SelectedRecentWorks />} />
                <Route path="Selected-Early-Works/StillLifes" element={<StillLifes />} />
                <Route path="Selected-Early-Works/interiors" element={<Interiors />} />
                <Route path="Selected-Early-Works/humans-and-animals" element={<InteriorsCopy />} />
                <Route path="Selected-Early-Works/structure-on-the-landscape" element={<StructureOnTheLandscape />} />
                <Route path="Selected-Recent-Works/coastal-places-and-things" element={<CoastalPlacesAndThings />} />
                <Route path="Selected-Recent-Works/entropy-limitation-and-death" element={<EntropyLimitationAndDeath />} />
                <Route path="Selected-Recent-Works/playgrounds" element={<Playground />} />
                <Route path="Selected-Recent-Works/human-animal-data" element={<HumanAnimalData />} />
                <Route path="Selected-Recent-Works/miscellaneous" element={<Miscellaneous />} />
                <Route path="contact" element={<Contact />} />
                <Route path="terms-conditions" element={<TermsConditions />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
            <Footer />
        </>
    )
}

export default BrowserRouterApp
