import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ModalComponent from '../../Modal/ModalCom.js';
import { HumansAndAnimals } from "../../Data.js";

const HumanAndAnimals = () => {
    const data = {
        pageName: "Human And Animals",
        comment: ["With regard to applying pigment I found myself wishing to free myself up a bit. I wanted to eliminate some of the detail that bogged me down at times, making the act of painting more tedious than I thought it should be. I considered this question – why shouldn't painting be pleasurable? My solution was to put down my brushes and paint with painting knives. In doing so I was prevented from getting too caught up with the kind of detailing that I now wished to ignore. It forced me to focus on the essential aspects of a thing or things. And it forced me to a greater degree of abstraction (though only on the rare occasion have I ventured into pure abstraction. To my way of thinking such paintings might better amplify what is most real in terms of visual perception. My recently new status as a father of a young boy provided fertile ground for new subjects and material. Watching my son's early explorations of the beach sparked memories about the joy of discovering things anew. I wanted to make paintings that spoke to this  and that joined what one is witnessing and celebrating with that which lives in memory. To better capture this dual sensation, I painted these images from visual notes and memory as opposed to constant direct observation, as had been my modus operandi up until this point. In this same line of exploration, I painted animals for the first time and such creatures appear later in my career. Some of these,", <em key="italic">Pastoral Eclipse AM </em>, "and", <em key="italic">Pastoral Eclipse PM </em>, ", for example, focus on landscape elements, as well. Please see the information that appears below these two images."]

    };

    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };
    return (
        <>
            <div className='stillLifes' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>Human And Animals</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        HumansAndAnimals.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={HumansAndAnimals.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default HumanAndAnimals
