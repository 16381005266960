import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ModalComponent from '../../Modal/ModalCom.js';
import { Entropylimitationanddeath } from "../../Data.js";
const EntropyLimitationAndDeath = () => {
    const data = {
        pageName: "Entropy, Limitation and Death",
        head1: "Entropy",
        haed1desc: ["As humans we are nature every bit as much as the organic elements external to us.  This realization inspired me to make paintings that in varying ways might lead the viewer to consider the link between human life and the earth around us, and the inevitable decline of physical form.", <br></br>, , <br></br>, "The first of my “entropy paintings”,", <em key="italic"> Double Negative 1, </em>, " 2016, was inspired by a visit to Michael Heizer’s", , <em key="italic"> Double Negative, </em>, "1969, a massive earth work in a remote area of Nevada. A huge rectangular trench dug into one mesa faces its complement on a mesa opposite it; the two divided by a broad valley. It suffers the slow continual erosion of its elements until which time they are transformed into the undifferentiated dust of the desert floor.  We humans too are subject to decay, destined to return to the earth – a sobering thought, but oddly beautiful as well.  In my three versions of", , <em key="italic"> Double Negative, </em>, "2016 I attempted to bring the feeling of the stunning, overwhelming site into a two-dimensional format while maintaining the visceral sensation of vast space. I applied raw layers of highly keyed pigment to recreate the profound and barren vista.",],
        head2: "Limitations",
        haed2desc: ["In 2016 I began to suffer a spinal disease that would become severe a few years later. My pain became so debilitating that I could not walk more than 50 feet or stand for more than a minute.  Luckily, I could still paint, but I required the use of a wheelchair whenever I went to an art museum, grocery store, etc.  How physical limitations alter one’s view of the self is the subject of these paintings. The triptychs included in this section were inspired by a trip to the UK in 2018.  When I saw an old gentleman in a wheelchair in Hyde Park a vision of three panels came to me – on one end was the man who appeared to be deeply reflecting upon something, perhaps a remembrance, and on the other end was my visual memory of Provence Canyons that I had visited recently in southwestern Georgia. Dividing them was an area of shimmering light undefined by shape or form. I intuited a link between the canyons (that had resulted by the forces of entropy) and this man’s condition.  It only made sense to bring this vision to life.", <br></br>, , <br></br>, "The disconnect between sexual desire and being physically compromised as it affects the male psyche is seen in", <em key="italic"> Entropy & Limitation Series: Who Are You Really, What Are We Gonna Do? Can you? </em>, "2021 Here, a young woman standing naked, pants dropped to her ankles stares at a wheelchair, that is literally empty, but an occupant is implied by her stare.  The unseen (assumed) man represents unattainable desire."],
        head3: "Death",
        haed3desc: ["Within a short span of time, I lost two family members and witnessed the agonizing death of my wife’s father.  I struggled to come to terms not so much with their passing but rather my undecipherable reaction or non-reaction to their deaths.  I did not bear the overt symptoms of loss that I would have expected of myself.  When executing my first painting of", <em key="italic"> Double Negative  </em>, "it dawned on me that it was in fact about my mother’s recent death.  It was cathartic.  Though death, particularly thinking about my own, has been a difficult, even paralyzing subject, I felt the need to paint about death itself and its effects on the living as witnessed in humans and animals. I term this series of paintings “Death Mort”.   It is a grim subject, but it has provided the much-needed opportunity to stare death in the face.",]
    }
    // MODAL FOR IMAGES 
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };

    return (
        <>
            <div className='stillLifes structureLandscape for-visibilty' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>Entropy, Limitation and Death</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        Entropylimitationanddeath.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={Entropylimitationanddeath.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default EntropyLimitationAndDeath
