import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { MiscellaneousData } from "../../Data.js";
const Miscellaneous = () => {
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };
    return (
        <>
            <div className='stillLifes structureLandscape' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>miscellaneous</h2>
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        MiscellaneousData.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={MiscellaneousData.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default Miscellaneous
