import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ModalComponent from '../../Modal/ModalCom.js';
import { HumansAnimals2 } from "../../Data.js";
const HumanAnimalData = () => {
    const data = {
        pageName: "Humans and Animals",
        comment: ["After an extended focus on still life and interior subject matter I was eager to widen my scope.  The human and animal paintings begin with both the witnessed and imagined.  An example of the former is", , <em key="italic"> Meditation on a Pocket,  </em>, "2012. While at a beach resort billiard room I watched my son anticipate his next play and at the same time I recalled watching his approach to the surf earlier that day.  I conceived of a triptych as the logical means for a visual narrative, memorializing a special day.  Examples of the latter are", <em key="italic"> Waiting, </em>, "2012", <em key="italic"> Bear Market, </em>, "2014, and", , <em key="italic"> Lost in Reading, </em>, "2022-23 .These works, I believe, express certain states-of-being that invite the viewer to ponder.", <br></br>, , <br></br>, "Anthropomorphic depictions are sometimes a better way of calling attention to compelling aspects of human existence, thus my foray into animal imagery.   The dog paintings", <em key="italic"> (e.g., Encounter, Sniff and Play, </em>, "2012", , <em key="italic"> Blue Canine </em>, ", 2012, et al) portray human interactions and responses."]
    }
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };
    return (
        <>
            <div className='stillLifes structureLandscape' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>Humans and Animals</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        HumansAnimals2.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={HumansAnimals2.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default HumanAnimalData
