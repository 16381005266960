import React from 'react'
import { RecentWork } from "../Data";
import { Link } from 'react-router-dom';
const SelectedRecentWorks = () => {
    return (
        <div className='SelectedEarlyWorks top-spacing' id='SelectedEarlyWorks'>
            <div className='container'>
                <div className='row'>
                    <h2>Selected Recent Works(2000-PRESENT)</h2>
                    {RecentWork.map((item) => {
                        return <div className='col-md-6 box-top-spacing'>
                            <Link to={item.link}>
                                <div className='selectedImgBox leftBox'>
                                    <img src={item.img} alt={item.alt} title={item.title} loading="lazy"></img>
                                    <h1>{item.name}</h1>
                                </div>
                            </Link>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default SelectedRecentWorks
