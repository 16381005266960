import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { playgroundData } from "../../Data.js";
import ModalComponent from '../../Modal/ModalCom.js';
const EntropyLimitationAndDeath = () => {
    const data = {
        pageName: "playgrounds",
        comment: ["The first nine years of the new century largely was a dark period that made regular artistic practice exceedingly difficult. There were positive and meaningful moments, though. In retrospect they were instrumental in leading me eventually to an exceptionally good place. I continued a series of work that focused on playground equipment. As a sole custodial, single parent I went to playgrounds often. Swings and slides provided an impetus for looking backward to simpler and more secure times. These symbolic forms and their spaces were painted with knives in an abstract manner capturing their echoes of promise within the dim context.", <em key="italic"> Swing Series: Evening, </em>, "2000", , <em key="italic"> and Slide Twilight #3, </em>, "2001, like most others in this series, display deep but intense color fields eclipsed by blackish slashes of diagonals representing the constructions. The resulting fractured shapes of luminous energy offer evidence of something unseen, but vital."]
    }
    // MODAL FOR IMAGES 
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setLightboxOpen(false);
    };
    return (
        <>
            <div className='stillLifes structureLandscape' id='stillLifes'>
                <div className='container'>
                    <div className='row'>
                        <h2 className='color-chng'>playgrounds</h2>
                        <ModalComponent {...data} />
                        <div className='modal-gallery'>
                            <div className='container imagegallery'>
                                <div className='row'>
                                    {
                                        playgroundData.map((photo, index) => {

                                            return (
                                                <div className='col-md-4 imagegallerycol' key={index}>
                                                    <GalleryPhoto photo={photo} index={index} openLightbox={openLightbox} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className='modal-box'>
                                < ModalGateway >
                                    {lightboxOpen && (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={playgroundData.map(photo => ({
                                                    ...photo,
                                                    srcset: `${photo.src} ${photo.width}w`,
                                                    caption: photo.title
                                                }))}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
const GalleryPhoto = ({ photo, index, openLightbox }) => (
    <div onClick={(event) => openLightbox(event, { photo, index })}>
        <img src={photo.src} alt={`Gallery ${index + 1}`} title={photo.caption} className={photo.className} />
    </div>
);

export default EntropyLimitationAndDeath
